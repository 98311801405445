import { enumSubscriptionPaymentFrequency } from '..';
import { MdbError } from '../../../errorObjects/MdbError';
import { enumMdbErrorType } from '../../../errorObjects/enums';

/**
 * @class enumSubscriptionPaymentFrequencyConvert Provides to/from string conversions for enumSubscriptionPaymentFrequency values.
 */
export class enumSubscriptionPaymentFrequencyConvert {
  public static toString(subscriptionPaymentFrequencyEnum: enumSubscriptionPaymentFrequency): string {
    return subscriptionPaymentFrequencyEnum.toString();
  }

  public static fromString(SubscriptionTypeString: string): enumSubscriptionPaymentFrequency {
    let subscriptionPaymentFrequencyEnum: enumSubscriptionPaymentFrequency = enumSubscriptionPaymentFrequency.Annually;

    switch (SubscriptionTypeString) {
      case enumSubscriptionPaymentFrequencyConvert.toString(enumSubscriptionPaymentFrequency.Annually):
        subscriptionPaymentFrequencyEnum = enumSubscriptionPaymentFrequency.Annually;
        break;

      case enumSubscriptionPaymentFrequencyConvert.toString(enumSubscriptionPaymentFrequency.Monthly):
        subscriptionPaymentFrequencyEnum = enumSubscriptionPaymentFrequency.Monthly;
        break;

      case enumSubscriptionPaymentFrequencyConvert.toString(enumSubscriptionPaymentFrequency.NoPaymentRequired):
        subscriptionPaymentFrequencyEnum = enumSubscriptionPaymentFrequency.NoPaymentRequired;
        break;

      default:
        throw new MdbError(`enumSubscriptionPaymentFrequency.fromString. Unrecognized/unsupported enumSubscriptionPaymentFrequency string: ${SubscriptionTypeString}`, enumMdbErrorType.InvalidOperation);
    }

    return subscriptionPaymentFrequencyEnum;
  }
}