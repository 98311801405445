import { RandomId } from '../../utilities/RandomId';
import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../types';
import { enumObjectPersistenceState, enumPersistableObjectType, enumPersistableObjectClassName } from '../../enums';
import { IUserRedemptionCodeRequest, IUserRedemptionCodeRequestAsJson } from '.';
import { VersionAwarePersistable } from '../persistence/VersionAwarePersistable';
import { JsonConverter } from '../../utilities/JsonConverter';
import { IUserPersistenceData } from '../persistence/UserPersistenceData';
import { enumSubscriptionType } from '../../enums';

/** 
 * @class UserRedemptionCodeRequest represents a request for a redemption code
 */
export class UserRedemptionCodeRequest extends VersionAwarePersistable implements IUserRedemptionCodeRequest {

  /**
   * @method Constructor method
   * @param {typeUniqueId} ownerId The Id of the owner (user or channel) of the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {typeUniqueId} redemptionCode The redemption code (will be generated as a unique identifier, but this is not the primary key (id) for the record)
   * @param {string} recipientName The name of person who is to be the recipient of the redemption code
   * @param {string} recipientEmailAddress The email address of person who is to be the recipient of the redemption code
   * @param {typeUniqueId} authorizerUserId The User Id of person who authorized the request for a redemption code
   * @param {string} authorizerEmailAddress The email address of person who authorized the request for a redemption code
   * @param {enumSubscriptionType} subscriptionType The type of subscription that will be allowed of the recipient 
   * @param {Date} startDate The starting date of the subscription for which the redemption code can be applied
   * @param {Date} endDate The ending (expiration) date of the subscription for which the redemption code can be applied
   * @param {IUserPersistenceData} userPersistenceData User-related persistence data
   */
  constructor(
    ownerId: typeUniqueId,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    redemptionCode: string,
    recipientName: string,
    recipientEmailAddress: string,
    authorizerUserId: typeUniqueId,
    authorizerEmailAddress: string,
    subscriptionType: enumSubscriptionType,
    startDate: Date,
    endDate: Date,
    userPersistenceData?: IUserPersistenceData
  ) {
    super(ownerId, enumPersistableObjectClassName.UserRedemptionCodeRequest, enumPersistableObjectType.UserRedemptionCodeRequest, id, parentObjectType, parentId, objectState, userPersistenceData);

    // assign name & description passed in
    this._redemptionCode = redemptionCode;
    this._recipientName = recipientName;
    this._recipientEmailAddress = recipientEmailAddress.toLowerCase();
    this._authorizerUserId = authorizerUserId;
    this._authorizerEmailAddress = authorizerEmailAddress.toLowerCase();
    this._subscriptionType = subscriptionType;
    this._startDate = startDate;
    this._endDate = endDate;
  }

  /*-----------------------------------------------*/
  /**
   * @property {string} _redemptionCode The redemption code (will be generated as a unique identifier, but this is not the primary key (id) for the record)
   */
  private _redemptionCode: string;

  /**
   * @method redemptionCode Getter method for _redemptionCodee
   */
  get redemptionCode(): string {
    return this._redemptionCode;
  }

  /**
   * @method redemptionCode Setter method for _redemptionCode
   * @param {string} value The input value for setting _redemptionCode
   */
  set redemptionCode(value: string) {
    this._redemptionCode = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _recipientName The name of person who is to be the recipient of the redemption code
   */
  private _recipientName: string;

  /**
   * @method recipientName Getter method for _recipientNamee
   */
  get recipientName(): string {
    return this._recipientName;
  }

  /**
   * @method recipientName Setter method for _recipientName
   * @param {string} value The input value for setting _recipientName
   */
  set recipientName(value: string) {
    this._recipientName = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _recipientEmailAddress The email address of person who is to be the recipient of the redemption code
   */
  private _recipientEmailAddress: string;

  /**
   * @method recipientEmailAddress Getter method for _recipientEmailAddresse
   */
  get recipientEmailAddress(): string {
    return this._recipientEmailAddress;
  }

  /**
   * @method recipientEmailAddress Setter method for _recipientEmailAddress
   * @param {string} value The input value for setting _recipientEmailAddress
   */
  set recipientEmailAddress(value: string) {
    this._recipientEmailAddress = value.toLowerCase();
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} _authorizerUserId The UserId of person who authorized the request for a redemption code
   */
  private _authorizerUserId: typeUniqueId;

  /**
   * @method authorizerUserId Getter method for _authorizerUserIde
   */
  get authorizerUserId(): typeUniqueId {
    return this._authorizerUserId;
  }

  /**
   * @method authorizerUserId Setter method for _authorizerUserId
   * @param {typeUniqueId} value The input value for setting _authorizerUserId
   */
  set authorizerUserId(value: typeUniqueId) {
    this._authorizerUserId = value.toLowerCase();
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _authorizerEmailAddress The email address of person who authorized the request for a redemption code
   */
  private _authorizerEmailAddress: string;

  /**
   * @method authorizerEmailAddress Getter method for _authorizerEmailAddresse
   */
  get authorizerEmailAddress(): string {
    return this._authorizerEmailAddress;
  }

  /**
   * @method authorizerEmailAddress Setter method for _authorizerEmailAddress
   * @param {string} value The input value for setting _authorizerEmailAddress
   */
  set authorizerEmailAddress(value: string) {
    this._authorizerEmailAddress = value.toLowerCase();
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property enumSubscriptionType _subscriptionType The type of subscription that will be allowed of the recipient 
   */
  private _subscriptionType: enumSubscriptionType;

  /**
   * @method subscriptionType Getter method for _subscriptionType
   */
  get subscriptionType(): enumSubscriptionType {
    return this._subscriptionType;
  }

  /**
   * @method subscriptionType Setter method for _subscriptionType
   * @param {enumSubscriptionType} value The input value for setting _subscriptionType
   */
  set subscriptionType(value: enumSubscriptionType) {
    this._subscriptionType = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property Date _startDate The starting date of the subscription for which the redemption code can be applied
   */
  private _startDate: Date;

  /**
   * @method startDate is an optional getter method for _startDate
   */
  get startDate() {
    return this._startDate;
  }

  /**
   * @method startDate is an optional setter method for _startDate
   * @param {Date} value is the input value for setting _startDate
   */
  set startDate(value) {
    this._startDate = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property Date _endDate The ending (expiration) date of the subscription for which the redemption code can be applied
   */
  private _endDate: Date | undefined = new Date();

  /**
   * @method endDate is an optional getter method for _endDate
   */
  get endDate() : Date | undefined {
    return this._endDate;
  }

  /**
   * @method endDate is an optional setter method for _endDate
   * @param {Date} value is the input value for setting _endDate
   */
  set endDate(value: Date | undefined) {
    this._endDate = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IUserRedemptionCodeRequest} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IUserRedemptionCodeRequest {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IUserRedemptionCodeRequest = Object.create(UserRedemptionCodeRequest.prototype);
    Object.assign(copyOfObject, this);

    // copy the contained objects

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): IUserRedemptionCodeRequestAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: IUserRedemptionCodeRequestAsJson = super.toJSON(includeContainedObjects);

      // copy any additional field values to the json object 
      jsonObject.redemptionCode = this.redemptionCode;
      jsonObject.recipientName = this.recipientName;
      jsonObject.recipientEmailAddress = this.recipientEmailAddress;
      jsonObject.authorizerEmailAddress = this.authorizerEmailAddress;
      jsonObject.subscriptionType = this.subscriptionType;
      jsonObject.startDate = this.startDate.toISOString();
      jsonObject.endDate = this.endDate ? this.endDate.toISOString() : undefined;

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {IUserRedemptionCodeRequestAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A ImageLink instance with values copied from the jsonObject
   */
  static fromJSON(jsonObject: IUserRedemptionCodeRequestAsJson, includeContainedObjects: boolean = true): IUserRedemptionCodeRequest {
    try {
      // create a new instance of this class
      let UserRedemptionCodeRequestObject: UserRedemptionCodeRequest = Object.create(UserRedemptionCodeRequest.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      UserRedemptionCodeRequestObject = super.fromJSONProtected(UserRedemptionCodeRequestObject, jsonObject, includeContainedObjects);

      // copy any additional field values from the json object 
      if (jsonObject.redemptionCode) {
        UserRedemptionCodeRequestObject.redemptionCode = jsonObject.redemptionCode;
      }

      if (jsonObject.recipientName) {
        UserRedemptionCodeRequestObject.recipientName = jsonObject.recipientName;
      }

      if (jsonObject.recipientEmailAddress) {
        UserRedemptionCodeRequestObject.recipientEmailAddress = jsonObject.recipientEmailAddress;
      }

      if (jsonObject.authorizerEmailAddress) {
        UserRedemptionCodeRequestObject.authorizerEmailAddress = jsonObject.authorizerEmailAddress;
      }

      if (jsonObject.subscriptionType) {
        UserRedemptionCodeRequestObject.subscriptionType = jsonObject.subscriptionType;
      }

      if (jsonObject.startDate) {
        UserRedemptionCodeRequestObject.startDate = new Date(jsonObject.startDate);
      }

      if (jsonObject.endDate) {
        UserRedemptionCodeRequestObject.endDate = new Date(jsonObject.endDate);
      }

      // if request is to include contained objects, copy additional fields
      if (includeContainedObjects) {
      }

      return UserRedemptionCodeRequestObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
