import { typeUniqueId } from "../../../../dataObjects/types";
import { getUserIdFromEmailAddress } from "../../../securityServices/userAccount/userAccountActions";
import { expungeUserAccountFromUserId } from ".";
import { enumMdbErrorType } from "../../../../errorObjects/enums";
import { MdbError } from "../../../../errorObjects/MdbError";


/**
 * @function expungeUserAccountFromEmailAddress Expunges a User Account and all associated resources, given an email address as a parameter
 * @param {string} emailAddress Email address for the user account to be expunged
 * @returns {Promise<void>} A Promise with no return value, providing for an asynchronous process.
 */
 export function expungeUserAccountFromEmailAddress(userAccountEmailAddress: string): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {

      const userAccountUserId: typeUniqueId | undefined = await getUserIdFromEmailAddress(userAccountEmailAddress.toLowerCase());

      // if unable to find a user account with the given email address, throw an error (exception)
      if (userAccountUserId === undefined) {
        throw new MdbError(`Unable to find a user account with the given email address (${userAccountEmailAddress})`, enumMdbErrorType.DataNotFound);
      } else {
        // call a method to expunge the user account given a userId 
        await expungeUserAccountFromUserId(userAccountUserId);
      }

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}
