import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { IObjectSharingRequestTracker } from '../../../dataObjects/models/collaboration/ObjectSharingTracker';

// export interface IAcceptSharingRequestStatusState {
//   value: enumWorkflowState | null
// }

const initialState: enumWorkflowState | null = null;

// whether to display console logs (displayConsoleLogs && console.log statements)
const displayConsoleLogs: boolean = false;

export const sharingRequestDeclineStatusSlice = createSlice({
  name: 'sharingRequestDeclineStatus',
  initialState,
  reducers: {
    // The sharingRequestDeclineRequest triggers the saving of an ObjectSharingRequestTracker. It's not really associated with the Store State
    // for the 'saveObjectSharingRequestTrackerStatus' store variable; however, it is used to both trigger the ObjectSharingRequestTracker Save Request -AND-
    // to call the objectSharingRequestTrackerSaveStatusChange() action to set the state to 'Requested'.
    // If we didn't use this design pattern, the most likely alternative would require creating another Store State
    // variable (eg, 'saveObjectSharingRequestTrackerData') and create another slice with a objectSharingRequestTrackerSaveRequest action. 
    sharingRequestDeclineRequest: (state, action: PayloadAction<IObjectSharingRequestTracker>) => {
      displayConsoleLogs && console.log(`sharingRequestDeclineStatusSlice/sharingRequestDeclineRequest. state: ${JSON.stringify(state)}; action: ${JSON.stringify(action)}`);
      sharingRequestDeclineStatusChange(enumWorkflowState.Requested);
    },
    // action has a payload with an IObjectSharingRequestTracker object or null
    sharingRequestDeclineStatusChange(state, action) {
      displayConsoleLogs && console.log(`sharingRequestDeclineStatusSlice/sharingRequestDeclineStatusChange. state: ${JSON.stringify(state)}; action: ${JSON.stringify(action)}`);
      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { sharingRequestDeclineRequest, sharingRequestDeclineStatusChange } = sharingRequestDeclineStatusSlice.actions;

// export the core reducer for the slice
export const sharingRequestDeclineStatusReducer = sharingRequestDeclineStatusSlice.reducer;

export default sharingRequestDeclineStatusSlice.reducer;