import { enumSubscriptionType } from '..';
import { MdbError } from '../../../errorObjects/MdbError';
import { enumMdbErrorType } from '../../../errorObjects/enums';

/**
 * @class enumSubscriptionTypeConvert Provides to/from string conversions for enumSubscriptionType values.
 */
export class enumSubscriptionTypeConvert {
  public static toString(subscriptionTypeEnum: enumSubscriptionType): string {
    return subscriptionTypeEnum.toString();
  }

  public static fromString(SubscriptionTypeString: string): enumSubscriptionType {
    let subscriptionTypeEnum: enumSubscriptionType = enumSubscriptionType.BetaTesting;

    switch (SubscriptionTypeString) {
      case enumSubscriptionTypeConvert.toString(enumSubscriptionType.BetaTesting):
        subscriptionTypeEnum = enumSubscriptionType.BetaTesting;
        break;

      case enumSubscriptionTypeConvert.toString(enumSubscriptionType.Group):
        subscriptionTypeEnum = enumSubscriptionType.Group;
        break;

      case enumSubscriptionTypeConvert.toString(enumSubscriptionType.Individual):
        subscriptionTypeEnum = enumSubscriptionType.Individual;
        break;

      default:
        throw new MdbError(`enumSubscriptionTypeConvert.fromString. Unrecognized/unsupported enumSubscriptionType string: ${SubscriptionTypeString}`, enumMdbErrorType.InvalidOperation);
    }

    return subscriptionTypeEnum;
  }
}