import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ControlsStringAssets, MessagesStringAssets } from '../../../assets/stringAssets';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormWithActionBar from '../FormWithActionBar/FormWithActionBar';
import { ICurrentUserContextData, useCurrentUserContext } from '../../providersAndContexts/currentUser';
import EmailInput from '../../controls/EmailInput/EmailInput';
import isEmail from 'validator/lib/isEmail';


interface IExpungeUserFormValues {
  email: string;
}

// // using 'yup', set up a schema for the form field values
// const schema = yup.object().shape({
//   email: yup
//     .string()
//     .email(ControlsStringAssets.emailInvalid)
//     .required(ControlsStringAssets.emailRequired),
// });

// // declare a yup schema object with details to be set up dynamically
// let schema = yup.object().shape({});


/**
 * @interface IExpungeUserFormProps Properties for the ExpungUserForm component
 */
export interface IExpungeUserFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {string} email The email address of the user account to be expunged
   */
  email: string,
  /**
   * @property {boolean} expungeRequestInProgress Whether an expunge request is in progress
   */
  expungeRequestInProgress?: boolean,
  /**
   * @property {(email: string) => Promise<void>} onSubmit Method to call for submitting the form for a save operation
   */
  onSubmit: (email: string) => Promise<void>,
}

const ExpungeUserForm: React.FC<IExpungeUserFormProps> = (props: IExpungeUserFormProps) => {
  ExpungeUserForm.displayName = 'Expunge User Form';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  // get required arguments from props
  const { email, onSubmit } = props;

  // use a custom hook to get the Current User information from a CurrentUserContext/Provider higher up in the component tree
  const { currentUser }: ICurrentUserContextData = useCurrentUserContext();
  displayConsoleLogs && console.log(`%c ExpungeUserForm. currentUser: \n${JSON.stringify(currentUser)}`, 'background: #600; color: #fff');

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(ControlsStringAssets.emailInvalid)
      .required(ControlsStringAssets.emailRequired)
      // .test("is-valid", (message) => `${message.path} is invalid`, (value) => value ? isEmail(value) : new yup.ValidationError(ControlsStringAssets.emailRequired))
      .test("is-email-same-as-current-user", ControlsStringAssets.expungeUserEmailCantMatchCurrentUser, () => {
        return (!(currentUser?.userSettings.email.trim().toLowerCase() === getValues('email').trim().toLowerCase()));
      }),
  });

  // set up details for ReactHookForm
  const { control, formState, formState: { errors }, getValues, handleSubmit, register, trigger } = useForm<IExpungeUserFormValues>({
    defaultValues: {
      email: email
    },
    // mode: "onBlur",
    mode: "all",
    resolver: yupResolver(schema)
  });

  // const { ref: emailReg, ...emailProps } = register("email", { required: true });

  // for testing whether the form is in a valid state
  const { isValid } = formState;

  const [formIsValid, setFormIsValid] = useState<boolean>(false);

  // // A function to dynamically set the Yup schema on demand
  // const setYupSchema: () => void = useCallback((): void => {
  //   displayConsoleLogs && console.log(`In ExpungeUserForm.setYupSchema. Email matches that of current user: ${currentUser?.userSettings.email.toLowerCase() === getValues('email').toLowerCase()}`);

  //   schema = yup.object().shape({
  //     email: yup
  //       .string()
  //       .email(ControlsStringAssets.emailInvalid)
  //       .required(ControlsStringAssets.emailRequired)
  //       // .test("is-valid", (message) => `${message.path} is invalid`, (value) => value ? isEmail(value) : new yup.ValidationError(ControlsStringAssets.channelSharingRecipientEmailRequired))
  //       .test("is-email-same-as-current-user", ControlsStringAssets.expungeUserEmailCantMatchCurrentUser, () => {
  //         return (!(currentUser?.userSettings.email.trim().toLowerCase() === getValues('email').trim().toLowerCase()));
  //       }),
  //   });

  // }, [currentUser, getValues('email')]);

  // // useEffect dependent on changes to the currentUser and whether the To Expunge Email matches that of the current user
  // useEffect(() => {
  //   setYupSchema();
  // }, [currentUser, getValues('email')]);

  useEffect(() => {
    setFormIsValid(isValid);
    // console.info(`UserSettings.useEffect: formIsValid being set to ${isValid}`);
  }, [isValid]);

  // capture whether an expunge request is currently being submitted
  const expungeRequestInProgress: boolean = props.expungeRequestInProgress ?? false;

  // state value indicating whether an expunge is in progress
  const [expungeInProgress, setExpungeInProgress] = useState<boolean>(expungeRequestInProgress);

  displayConsoleLogs && console.log(`In ExpungeUserForm. expungeRequestInProgress: ${expungeRequestInProgress}; expungeInProgress: ${expungeInProgress}`);

  // useEffect hook for setting the 'saveInProgress' local state based on whether a save is currently in progress
  useEffect(() => {
    setExpungeInProgress(expungeRequestInProgress);
  }, [expungeRequestInProgress]);

  // handles a save/submit request from the form
  const handleSaveSubmit = async (data: IExpungeUserFormValues) => {

    setExpungeInProgress(true);

    // call the onSubmit handler passed in, supplying the User object
    await onSubmit(data.email);
  }

  // present the form
  return (
    <>
      <FormWithActionBar
        onSubmit={handleSubmit(handleSaveSubmit)}
        actionInProgress={expungeInProgress}
        actionInProgressLabel={MessagesStringAssets.user_ExpungeUserAccountRequested}
        formIsValid={formIsValid}
        submitButtonLabel='Expunge'
      >

        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, onBlur, value, ref }, fieldState: { isTouched, isDirty } }) => (
            <>
              <EmailInput
                control={control}
                trigger={trigger}
                autoFocus
                label={ControlsStringAssets.expungeEmailLabel}
                margin='normal'
                fullWidth
                error={!!errors.email}
                helperText={errors?.email?.message}
                InputLabelProps={{
                  required: true  // this will cause an asterisk ('*') to appear at the end of the label text
                }}
              />
            </>
          )}
        />

      </FormWithActionBar>
    </>

  );
}

export default ExpungeUserForm;