import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { typeUniqueId } from '../../../../dataObjects/types';
import GenericPageContainer from '../../GenericPageContainer/GenericPageContainer';
import { IUser } from '../../../../dataObjects/models/users/User';
import { IUserRedemptionCodeRequest, UserRedemptionCodeRequest } from '../../../../dataObjects/models/userRedemptionCodeRequest';
import { IStoreState } from '../../../../uiMiddleware-redux/store/IStoreState';
import { getSingleObjectById } from '../../../../firebaseServices/dataServices/dataServiceActions/genericActions';
import { enumObjectPersistenceState, enumPersistableObjectType, enumSubscriptionType } from '../../../../dataObjects/enums';
import { IUserSettings } from '../../../../dataObjects/models/users/UserSettings';
import { RandomId } from '../../../../dataObjects/utilities/RandomId';
import { IUserPersistenceData, UserPersistenceData } from '../../../../dataObjects/models/persistence/UserPersistenceData';
import { MessagesStringAssets, PageAndViewTitleStringAssets } from '../../../../assets/stringAssets';
import { NotificationStringAssets } from '../../../../assets/stringAssets';
import { useProcessPerManageObjectPageStatus } from '../../../customHooks';
import { IProcessPerManageObjectPageStatusInput } from '../../../customHooks/hookInputObjects/ProcessPerManageObjectPage/IProcessPerManageObjectPageStatusInput';
import UserRedemptionCodeRequestForm from '../../../forms/UserRedemptionCodeRequestForm/UserRedemptionCodeRequestForm';
import { ICurrentUserContextData, useCurrentUserContext } from '../../../providersAndContexts/currentUser';
import { userRedemptionCodeRequestSaveRequest, userRedemptionCodeRequestSaveStatusChange } from '../../../../uiMiddleware-redux/slices/userRedemptionCodeRequest/userRedemptionCodeRequestSaveStatusSlice';
import { alertInfoChange } from '../../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';
import { useAppDispatch } from '../../../../uiMiddleware-redux/store/configureStore';

/**
 * @interface IManageUserRedemptionCodeRequestPageProps Input properties for the ManageTopicPage
 */
export interface IManageUserRedemptionCodeRequestPageProps extends PropsWithChildren<unknown> {
  id?: typeUniqueId  // if the Id is given, it means that the UserRedemptionCodeRequest already exists
}

/**
 * @function ManageUserRedemptionCodeRequestPage A React Function Component for manageing (creating or editing) a UserRedemptionCodeRequest.
 * @param {IManageUserRedemptionCodeRequestPageProps} props Input properties
 */
const ManageUserRedemptionCodeRequestPage: React.FC<IManageUserRedemptionCodeRequestPageProps> = (props: IManageUserRedemptionCodeRequestPageProps) => {

  ManageUserRedemptionCodeRequestPage.displayName = "Manage UserRedemptionCodeRequest Page";

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  displayConsoleLogs && console.log(`ManageUserRedemptionCodeRequestPage. Entered ManageUserRedemptionCodeRequestPage`);

  const dispatch = useAppDispatch();

  const params = useParams();

  // will be set to the 'id' provided in the URL parms. If an id was provided, it means that we will be editing an existing object.
  const [userRedemptionCodeRequestId, setUserRedemptionCodeRequestId] = useState<typeUniqueId | undefined>(params.id);

  // define an effect that will set the userRedemptionCodeRequestId anytime the params.id changes
  useEffect(() => {
    setUserRedemptionCodeRequestId(params.id);
  }, [params.id]);

  // define an effect based on a change to the userRedemptionCodeRequestId. Having a defined userRedemptionCodeRequestId means that we will 
  // be editing an existing UserRedemptionCodeRequest
  useEffect(() => {
    displayConsoleLogs && console.log(`ManageUserRedemptionCodeRequestPage. In useEffect for userRedemptionCodeRequestId`);

    if (userRedemptionCodeRequestId !== undefined) {
      // try to find an UserRedemptionCodeRequest object with the Id given in the URL
      getSingleObjectById(userRedemptionCodeRequestId, enumPersistableObjectType.UserRedemptionCodeRequest, onUserRedemptionCodeRequestFetched);
    }
  }, [userRedemptionCodeRequestId]);

  // will be set to the userRedemptionCodeRequest to be managed, either a new userRedemptionCodeRequest or an existing one
  const [userRedemptionCodeRequest, setUserRedemptionCodeRequest] = useState<IUserRedemptionCodeRequest | undefined>(undefined);

  // whether we are going to be managing a new userRedemptionCodeRequest
  const [isNewObject, setIsNewObject] = useState<boolean | undefined>(undefined);

  const resetSaveUserRedemptionCodeRequestStatus: () => void = useCallback(() => {
    dispatch(userRedemptionCodeRequestSaveStatusChange(null));
  }, [dispatch])

  const resetAlertInfo: () => void = useCallback(() => {
    dispatch(alertInfoChange(null));
  }, [dispatch])

  // perform any initialization required for this page when it comes into existence
  useEffect(() => {
    displayConsoleLogs && console.log(`ManageUserRedemptionCodeRequestPage. In useEffect for resetAlertInfo, resetSaveUserRedemptionCodeRequestStatus`);

    // clear areas in Redux state to start with a clean slate
    resetSaveUserRedemptionCodeRequestStatus();
    resetAlertInfo();

    return () => {
      // upon 'unmounting', clear areas of Redux state
      resetSaveUserRedemptionCodeRequestStatus();
      resetAlertInfo();
    }
  }, [resetAlertInfo, resetSaveUserRedemptionCodeRequestStatus]);

  // use a custom hook to get the Current User information from the CurrentUserProvider
  const { currentUser }: ICurrentUserContextData = useCurrentUserContext();

  // prepare a data structure that will be used to call a custom hook that will take care of the workflow for the page
  const processPerManageObjectPageStatusInput: IProcessPerManageObjectPageStatusInput = {
    workflowStateObj: (state: IStoreState) => state.saveUserRedemptionCodeRequestStatus,
    failureErrorStateObj: (state: IStoreState) => state.saveUserRedemptionCodeRequestFailure,
    notificationFailureMessageTemplate: MessagesStringAssets.userRedemptionCodeRequest_SaveFailure,
    notificationSuccessTitle: NotificationStringAssets.heading_SaveUserRedemptionCodeRequest,
    notificationSuccessMessage: MessagesStringAssets.userRedemptionCodeRequest_SaveSuccess,
  }

  if (userRedemptionCodeRequest !== undefined) {
    processPerManageObjectPageStatusInput.notificationSuccessMessage = `${MessagesStringAssets.userRedemptionCodeRequest_SaveSuccess} (${userRedemptionCodeRequest.recipientEmailAddress})`;
  }

  // call a custom hook to handle the workflow for the page (Requested, InProgress, Success, Failure)
  // const { saveInProgress, alertInfo } = useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput);
  const { operationInProgress } = useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput);

  /**
   * @function prepareNewUserRedemptionCodeRequest Prepares/creates a new userRedemptionCodeRequest that will be used if user is requesting to create/add a new userRedemptionCodeRequest.
   */
  const prepareNewUserRedemptionCodeRequest: (user: IUser, userSettings: IUserSettings) => IUserRedemptionCodeRequest = useCallback(
    (user: IUser, userSettings: IUserSettings) => {
      let newUserRedemptionCodeRequest: IUserRedemptionCodeRequest;

      const newUserRedemptionCodeRequestId: typeUniqueId = RandomId.newId();
      const userName: string = `${userSettings.firstName} ${userSettings.lastName}`;
      const userPersistenceData: IUserPersistenceData = new UserPersistenceData(user.id, userName);
      const redemptionCode: typeUniqueId = RandomId.newId();
      const defaultStartDate: Date = new Date();
      const defaultEndDate: Date = new Date(defaultStartDate);
      defaultEndDate.setDate(defaultStartDate.getDate() + 90);
      newUserRedemptionCodeRequest = new UserRedemptionCodeRequest(user.id, newUserRedemptionCodeRequestId, enumPersistableObjectType.User, undefined, enumObjectPersistenceState.New,
        redemptionCode, '', '', user.id, user.userSettings.email, enumSubscriptionType.BetaTesting, defaultStartDate, defaultEndDate, userPersistenceData);

      return newUserRedemptionCodeRequest;
    }, []
  );

  // define an effect based on a change to the userRedemptionCodeRequestParentId & currentUser. Having a defined userRedemptionCodeRequestParentId means that we will
  // be creating a new UserRedemptionCodeRequest
  useEffect(() => {
    displayConsoleLogs && console.log(`ManageUserRedemptionCodeRequestPage. In useEffect for userRedemptionCodeRequestParentId, currentUser, prepareNewUserRedemptionCodeRequest`);

    if (!params.id && currentUser) {
      setUserRedemptionCodeRequest(prepareNewUserRedemptionCodeRequest(currentUser, currentUser.userSettings));

      setIsNewObject(true);
    }
  }, [currentUser, prepareNewUserRedemptionCodeRequest]);

  function onUserRedemptionCodeRequestFetched(userRedemptionCodeRequest: IUserRedemptionCodeRequest | undefined): void {
    displayConsoleLogs && console.log(`ManageUserRedemptionCodeRequestPage. Entered onUserRedemptionCodeRequestFetched()`);

    if (userRedemptionCodeRequest !== undefined) {
      // set the returned UserRedemptionCodeRequest to be the one we are editing
      setUserRedemptionCodeRequest(userRedemptionCodeRequest);

      // set local 'isNewObject' state to 'false' to indicate that we are editing an existing object (default was set to 'true')
      setIsNewObject(false);
    }
  }

  /**
   * @method handleFormSubmit Handles a submit request from the form in this page
   * @param event A Submit event
   */
  function handleFormSubmit(userRedemptionCodeRequest: IUserRedemptionCodeRequest): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        // dispatch an action to request saving the userRedemptionCodeRequest
        dispatch(userRedemptionCodeRequestSaveRequest(userRedemptionCodeRequest));

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }


  return (
    <>
      <GenericPageContainer
        maxWidth="sm"
        showBackButton={true}
        pageTitle={isNewObject ? PageAndViewTitleStringAssets.pageTitle_ManageUserRedemptionCodeRequest_New : PageAndViewTitleStringAssets.pageTitle_ManageUserRedemptionCodeRequest_Existing}
        onCloseAlert={resetSaveUserRedemptionCodeRequestStatus}
      >
        {userRedemptionCodeRequest &&
          <UserRedemptionCodeRequestForm
            userRedemptionCodeRequest={userRedemptionCodeRequest}
            saveRequestInProgress={operationInProgress}
            onSubmit={handleFormSubmit} />
        }

      </GenericPageContainer>
    </>
  );


} // const ManageTopicPage...

export default ManageUserRedemptionCodeRequestPage;
