import { take, put } from 'redux-saga/effects';
import { expungeUserAccountFromEmailAddress } from '../../../firebaseServices/dataServices/dataServiceActions/userActions';
import { userAccountExpungeRequest } from '../../slices/user/userAccountExpungeDataSlice';
import { userAccountExpungeStatusChange } from '../../slices/user/userAccountExpungeStatusSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { userAccountExpungeFailure } from '../../slices/user/userAccountExpungeFailureSlice';

/**
 * @function expungeUserAccountAndResourcesSaga A saga workflow pertaining to expunging a user account and all resources associated with the account.
 */
export function* expungeUserAccountAndResourcesSaga() {
  // console.info('(expungeUserAccountAndResourcesSaga) entered expungeUserAccountAndResourcesSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the userAccountExpungeRequest action to be dispatched
      const userAccountExpungeRequestData = yield take(userAccountExpungeRequest);
      const { payload: emailAddress } = userAccountExpungeRequestData;

      // make a request to expunge the user account, per the email address
      yield expungeUserAccountFromEmailAddress(emailAddress.toLowerCase());

      // dispatch an action to indicate that the expunge operation was successful
      yield put(userAccountExpungeStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the expunging of the account failed
      yield put(userAccountExpungeStatusChange(enumWorkflowState.Failure));
      yield put(userAccountExpungeFailure(error));
    }
  }
}
