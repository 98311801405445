import { take, put } from 'redux-saga/effects';
import { populateRecordsToSearchMetadataDb } from '../../../firebaseServices/dataServices/dataServiceActions/cloudSqlActions/populateRecordsToSearchMetadataDb';
import { searchMetadataPopulateRequest } from '../../slices/searchMetadata/searchMetadataPopulateDataSlice';
import { searchMetadataPopulateStatusChange } from '../../slices/searchMetadata/searchMetadataPopulateStatusSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { searchMetadataPopulateFailure } from '../../slices/searchMetadata/searchMetadataPopulateFailureSlice';

/**
 * @function populateSearchMetadataDbSaga A saga workflow pertaining to expunging a user account and all resources associated with the account.
 */
export function* populateSearchMetadataDbSaga() {
  // console.info('(populateSearchMetadataDbSaga) entered populateSearchMetadataDbSaga');

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  displayConsoleLogs && console.log('%c (populateSearchMetadataDbSaga) Entered populateSearchMetadataDbSaga', 'background: #B0B; color: #fff');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the searchMetadataPopulateRequest action to be dispatched
      const searchMetadataPopulateRequestData = yield take(searchMetadataPopulateRequest);
      const { payload: emailAddress } = searchMetadataPopulateRequestData;

      displayConsoleLogs && console.log(`%c (populateSearchMetadataDbSaga) 'searchMetadataPopulateRequest' action detected. emailAddress: ${emailAddress}`, 'background: #B0B; color: #fff');

      //searchMetadataPopulateRequest
      // make a request to populate records into the SearchMetadataDb, per the email address (which could be undefined)
      yield populateRecordsToSearchMetadataDb(emailAddress.toLowerCase());

      displayConsoleLogs && console.log('%c (populateSearchMetadataDbSaga) Completed call to populateRecordsToSearchMetadataDb()', 'background: #B0B; color: #fff');

      // dispatch an action to indicate that the populate search metadata operation was successful
      yield put(searchMetadataPopulateStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the operation to populate search metadata failed
      yield put(searchMetadataPopulateStatusChange(enumWorkflowState.Failure));
      yield put(searchMetadataPopulateFailure(error));
    }
  }
}
