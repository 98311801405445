import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../../types';
import { enumObjectPersistenceState, enumPersistableObjectType, enumPersistableObjectClassName, enumSubscriptionType, enumSubscriptionPaymentFrequency, enumSubscriptionTypeConvert, enumSubscriptionPaymentFrequencyConvert } from '../../../enums';
import { IMembershipSubscription, IMembershipSubscriptionAsJson } from '.';
import { IUserPersistenceData } from '../../persistence/UserPersistenceData';
import { VersionAwarePersistable } from '../../persistence/VersionAwarePersistable';

/** 
 * @class MembershipSubscription An individual membership subscription
 */
export class MembershipSubscription extends VersionAwarePersistable implements IMembershipSubscription {
  /**
   * @method Constructor method
   * @param {typeUniqueId} ownerId The Id of the owner (user or channel) of the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {typeUniqueId} userId The unique identifier for the user that is associated with this subscription
   * @param {typeUniqueId} paymentResponsibilityUserId The unique identifier for the user that is associated with this subscription
   * @param {enumSubscriptionType} subscriptionType The type of subscription
   * @param {enumSubscriptionPaymentFrequency} subscriptionPaymentFrequency The frequency of payment for the subscription
   * @param {Date} startDate The starting date of the subscription
   * @param {Date | undefined} endDate The ending (expiration) date of the subscription
   * @param {number} maxGroupSubscriptions The maximum number of group subscriptions (including this one) that can be associated with this subscription
   * @param {number} remainingAvailableGroupSubscriptions The remaining number of group subscriptions (including this one) that can be associated with this subscription
   * @param {IUserPersistenceData} userPersistenceData User-related persistence data
   */
  constructor(
    ownerId: typeUniqueId,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    userId: typeUniqueId,
    paymentResponsibilityUserId: typeUniqueId,
    subscriptionType: enumSubscriptionType,
    subscriptionPaymentFrequency: enumSubscriptionPaymentFrequency,
    startDate: Date,
    endDate: Date | undefined,
    maxGroupSubscriptions: number,
    remainingAvailableGroupSubscriptions: number,
    userPersistenceData?: IUserPersistenceData
  ) {
    super(ownerId, enumPersistableObjectClassName.MembershipSubscription, enumPersistableObjectType.MembershipSubscription, id, parentObjectType, parentId, objectState, userPersistenceData);

    this._userId = userId;
    this._paymentResponsibilityUserId = paymentResponsibilityUserId;
    this._subscriptionType = subscriptionType;
    this._subscriptionPaymentFrequency = subscriptionPaymentFrequency;
    this._startDate = startDate;
    this._endDate = endDate;
    this._maxGroupSubscriptions = maxGroupSubscriptions;
    this._maxGroupSubscriptions = remainingAvailableGroupSubscriptions;
    this._remainingAvailableGroupSubscriptions = remainingAvailableGroupSubscriptions;
  }


  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} _userId The unique identifier for the user that is associated with this subscription
   */
  private _userId: typeUniqueId;

  /**
   * @method userId Getter method for _userId
   */
  get userId(): string {
    return this._userId;
  }

  /**
   * @method userId Setter method for _userId
   * @param {typeUniqueId} value The input value for setting _userId
   */
  set userId(value: typeUniqueId) {
    this._userId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} _paymentResponsibilityUserId The unique identifier for the user that is associated with this subscription
   */
  private _paymentResponsibilityUserId: typeUniqueId;

  /**
   * @method paymentResponsibilityUserId Getter method for _paymentResponsibilityUserId
   */
  get paymentResponsibilityUserId(): string {
    return this._paymentResponsibilityUserId;
  }

  /**
   * @method paymentResponsibilityUserId Setter method for _paymentResponsibilityUserId
   * @param {typeUniqueId} value The input value for setting _paymentResponsibilityUserId
   */
  set paymentResponsibilityUserId(value: typeUniqueId) {
    this._paymentResponsibilityUserId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {enumSubscriptionType} _subscriptionType The type of subscription
   */
  private _subscriptionType: enumSubscriptionType;

  /**
   * @method subscriptionType Getter method for _subscriptionType
   */
  get subscriptionType(): enumSubscriptionType {
    return this._subscriptionType;
  }

  /**
   * @method subscriptionType Setter method for _subscriptionType
   * @param {enumSubscriptionType} value The input value for setting _subscriptionType
   */
  set subscriptionType(value: enumSubscriptionType) {
    this._subscriptionType = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {enumSubscriptionPaymentFrequency} _subscriptionPaymentFrequency The frequency of payment for the subscription
   */
  private _subscriptionPaymentFrequency: enumSubscriptionPaymentFrequency;

  /**
   * @method subscriptionPaymentFrequency Getter method for _subscriptionPaymentFrequency
   */
  get subscriptionPaymentFrequency(): enumSubscriptionPaymentFrequency {
    return this._subscriptionPaymentFrequency;
  }

  /**
   * @method subscriptionPaymentFrequency Setter method for _subscriptionPaymentFrequency
   * @param {enumSubscriptionPaymentFrequency} value The input value for setting _subscriptionPaymentFrequency
   */
  set subscriptionPaymentFrequency(value: enumSubscriptionPaymentFrequency) {
    this._subscriptionPaymentFrequency = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {Date} _startDate The starting date of the subscription
   */
  private _startDate: Date;

  /**
   * @method startDate Getter method for _startDate
   */
  get startDate(): Date {
    return this._startDate;
  }

  /**
   * @method startDate Setter method for _startDate
   * @param {Date} value The input value for setting _startDate
   */
  set startDate(value: Date) {
    this._startDate = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {Date} _endDate The ending date of the subscription
   */
  private _endDate: Date | undefined = undefined;

  /**
   * @method endDate Getter method for _endDate
   */
  get endDate(): Date | undefined {
    return this._endDate;
  }

  /**
   * @method endDate Setter method for _endDate
   * @param {Date | undefined} value The input value for setting _endDate
   */
  set endDate(value: Date | undefined) {
    this._endDate = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {number} _maxGroupSubscriptions The maximum number of group subscriptions (including this one) that can be associated with this subscription
   */
  private _maxGroupSubscriptions: number;

  /**
   * @method maxGroupSubscriptions Getter method for _maxGroupSubscriptions
   */
  get maxGroupSubscriptions(): number {
    return this._maxGroupSubscriptions;
  }

  /**
   * @method maxGroupSubscriptions Setter method for _maxGroupSubscriptions
   * @param {number} value The input value for setting _maxGroupSubscriptions
   */
  set maxGroupSubscriptions(value: number) {
    this._maxGroupSubscriptions = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {number} _remainingAvailableGroupSubscriptions The maximum number of group subscriptions (including this one) that can be associated with this subscription
   */
  private _remainingAvailableGroupSubscriptions: number;

  /**
   * @method remainingAvailableGroupSubscriptions Getter method for _remainingAvailableGroupSubscriptions
   */
  get remainingAvailableGroupSubscriptions(): number {
    return this._remainingAvailableGroupSubscriptions;
  }

  /**
   * @method remainingAvailableGroupSubscriptions Setter method for _remainingAvailableGroupSubscriptions
   * @param {number} value The input value for setting _remainingAvailableGroupSubscriptions
   */
  set remainingAvailableGroupSubscriptions(value: number) {
    this._remainingAvailableGroupSubscriptions = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IMembershipSubscription} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IMembershipSubscription {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IMembershipSubscription = Object.create(MembershipSubscription.prototype);
    Object.assign(copyOfObject, this);

    // there are no contained objects to copy

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): IMembershipSubscriptionAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: IMembershipSubscriptionAsJson = super.toJSON(includeContainedObjects);

      // copy any additional field values to the json object 
      jsonObject.userId = this.userId;
      jsonObject.paymentResponsibilityUserId = this.paymentResponsibilityUserId;
      jsonObject.subscriptionType = this.subscriptionType;
      jsonObject.subscriptionPaymentFrequency = this.subscriptionPaymentFrequency;
      jsonObject.startDate = this.startDate.toISOString();
      jsonObject.endDate = this.endDate ? this.endDate.toISOString() : 'undefined';
      jsonObject.maxGroupSubscriptions = this.maxGroupSubscriptions;
      jsonObject.remainingAvailableGroupSubscriptions = this.remainingAvailableGroupSubscriptions;

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
        // this class is currently designed to have no contained objects
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {IMembershipSubscriptionAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns An instance of 'this' class with values copied from the jsonObject
   */
  static fromJSON(jsonObject: IMembershipSubscriptionAsJson, includeContainedObjects: boolean = true): IMembershipSubscription {
    try {
      // create a new instance of this class
      let membershipSubscriptionObject: MembershipSubscription = Object.create(MembershipSubscription.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      membershipSubscriptionObject = super.fromJSONProtected(membershipSubscriptionObject, jsonObject, includeContainedObjects);

      // copy any additional field values from the json object 
      if (jsonObject.userId) {
        membershipSubscriptionObject.userId = jsonObject.userId;
      }

      if (jsonObject.paymentResponsibilityUserId) {
        membershipSubscriptionObject.paymentResponsibilityUserId = jsonObject.paymentResponsibilityUserId;
      }

      if (jsonObject.subscriptionType) {
        membershipSubscriptionObject.subscriptionType = enumSubscriptionTypeConvert.fromString(jsonObject.subscriptionType);
      }

      if (jsonObject.subscriptionPaymentFrequency) {
        membershipSubscriptionObject.subscriptionPaymentFrequency = enumSubscriptionPaymentFrequencyConvert.fromString(jsonObject.subscriptionPaymentFrequency);
      }

      if (jsonObject.startDate) {
        membershipSubscriptionObject.startDate = new Date(jsonObject.startDate);
      }

      if (jsonObject.endDate) {
        if (jsonObject.endDate === undefined || jsonObject.endDate === 'undefined') {
          membershipSubscriptionObject.endDate = undefined;
        } else {
          if (jsonObject.endDate) {
            membershipSubscriptionObject.endDate = new Date(jsonObject.endDate);
          }
        }
      }

      if (jsonObject.maxGroupSubscriptions) {
        membershipSubscriptionObject.maxGroupSubscriptions = jsonObject.maxGroupSubscriptions;
      }

      if (jsonObject.remainingAvailableGroupSubscriptions) {
        membershipSubscriptionObject.remainingAvailableGroupSubscriptions = jsonObject.remainingAvailableGroupSubscriptions;
      }

      // if request is to include contained objects, copy additional fields
      if (includeContainedObjects) {
        // this class doesn't have any contained objects to add
      }

      return membershipSubscriptionObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
