import { Firestore } from 'firebase/firestore';
import { typeUniqueId } from '../../../../dataObjects/types';
import { FirestoreUserRepository_Ext, IFirestoreUserRepository_Ext } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreUserRepository';
import { FirebaseAppSingleton } from '../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton';


/**
 * @function getUserIdFromEmailAddress Given an email address, retrieves a userId for a user account info from the DB and, if found, 
 *                                              returns an IUser object
 * @param {string} emailAddress Email address for the user account to be expunged
 * @returns An IUser object or undefined
 * @returns {Promise<typeUniqueId | undefined>} A Promise with the userId that was found (or 'undefined' if not found), providing for an asynchronous process.
 */
export function getUserIdFromEmailAddress(userAccountEmailAddress: string): Promise<typeUniqueId | undefined> {
  return new Promise<typeUniqueId | undefined>(async (resolve, reject) => {
    try {
      // initialize a Firestore object instance
      const firestoreObj: Firestore = FirebaseAppSingleton.firestore;

      // instantiate a FirestoreUserRepository_Ext object using the Firestore object instance
      const firestoreUserRepository_Ext: IFirestoreUserRepository_Ext = new FirestoreUserRepository_Ext(firestoreObj);

      // call method to get the userId from the email address
      const userAccountUserId: typeUniqueId | undefined = await firestoreUserRepository_Ext.getUserIdFromEmailAddress(userAccountEmailAddress.toLowerCase());

      resolve(userAccountUserId);
    } catch (error: any) {
      reject(error);
    }
  });
}
