import { IObjectSharingRequestTracker } from "../../../../dataObjects/models/collaboration/ObjectSharingTracker";
import { enumSharingRequestStatus } from '../../../../dataObjects/enums';
import { saveObjectSharingRequestTracker } from '.';


/*-----------------------------------------------*/
/**
* @function updateSharingRequestStatusToAccepted Updates the status of a Sharing Request to the status provided.
* @param {IObjectSharingRequestTracker} sharingRequest The Sharing Request to be updated.
* @param {enumSharingRequestStatus} newStatus The new status to be assigned to the Sharing Request.
* @returns {Promise<void>} A Promise (to provide asynchrounous capability) with no value.
*/
export function updateSharingRequestStatus(sharingRequest: IObjectSharingRequestTracker, newStatus: enumSharingRequestStatus): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // set the status of the sharingRequest to the status provided
      sharingRequest.status = newStatus;

      // make a request to save the sharingRequest with the modified status
      await saveObjectSharingRequestTracker(sharingRequest);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });

}