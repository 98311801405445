import { getFunctions, httpsCallable } from "firebase/functions";
import { FirebaseAppSingleton } from "../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton";
import { IUserRedemptionCodeRequestAsJson } from "../../../../dataObjects/models/userRedemptionCodeRequest";

/**
 * @function getUserRedemptionCodeRequestsForEmailAddressViaHttp Calls a Firebase Function via Https to get UserRedemptionCodeRequests for a given email address.
 * @param {string} emailAddress The email address for which requests are to be sought.
 * @returns {void} No return value
 */
export function getUserRedemptionCodeRequestsForEmailAddressViaHttp(emailAddress: string): Promise<Array<IUserRedemptionCodeRequestAsJson>> {
  return new Promise<Array<IUserRedemptionCodeRequestAsJson>>(async (resolve, reject) => {
    try {
      // whether to display console logs (displayConsoleLogs && console.log statements)
      const displayConsoleLogs: boolean = false;

      displayConsoleLogs && console.log(`%c Entered getUserRedemptionCodeRequestsForEmailAddressViaHttp. emailAddress: ${emailAddress}`, 'background: #055; color: #fff');

      const functions = getFunctions(FirebaseAppSingleton.getInstance().firebaseApp);
      const getUserRedemptionCodeRequestsForEmailAddress =
        httpsCallable<string, Array<IUserRedemptionCodeRequestAsJson>>(functions, 'getUserRedemptionCodeRequestsForEmailAddress');
        getUserRedemptionCodeRequestsForEmailAddress(emailAddress.toLowerCase())
        .then((result) => {
          // Read result of the Cloud Function.
          /** @type {Array<IUserRedemptionCodeRequestAsJson>} */
          const userRedemptionCodeRequests: Array<IUserRedemptionCodeRequestAsJson> = result.data;

          displayConsoleLogs && console.log(`%c getUserRedemptionCodeRequestsForEmailAddressViaHttp(). Returned from Http call. userRedemptionCodeRequests: ${JSON.stringify(userRedemptionCodeRequests)}`, 'background: #055; color: #fff');

          resolve(userRedemptionCodeRequests);
        });
    } catch (error: any) {
      reject(error);
    }
  });
}
