import { take, put } from 'redux-saga/effects';
import { acceptSharingRequest, saveObjectSharingRequestTracker } from '../../../firebaseServices/dataServices/dataServiceActions/collaborationActions';
import { sharingRequestAcceptanceRequest, sharingRequestAcceptanceStatusChange } from '../../slices/collaboration/sharingRequestAcceptanceStatusSlice';
import { enumSharingRequestStatus, enumWorkflowState } from '../../../dataObjects/enums';
import { sharingRequestAcceptanceFailureChange } from '../../slices/collaboration/sharingRequestAcceptanceFailureSlice';

/**
 * @function acceptSharingRequestSaga A saga workflow pertaining to a user having requested to accept a Sharing Request.
 */
export function* acceptSharingRequestSaga() {
  // console.info('(acceptSharingRequestSaga) entered acceptSharingRequestSaga');

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the sharingRequestAcceptanceRequest action to be dispatched
      const sharingRequestAcceptanceRequestData = yield take(sharingRequestAcceptanceRequest);
      const { payload: objectSharingRequestTracker } = sharingRequestAcceptanceRequestData;

      displayConsoleLogs && console.log(`acceptSharingRequestSaga. Received notification of sharingRequestAcceptanceRequest. objectSharingRequestTracker: ${JSON.stringify(objectSharingRequestTracker)}`);

      // make a request to process the Accept Sharing Request, causing the channel in the DB to be updated to have the recipient user listed as a shared user on the channel
      displayConsoleLogs && console.log(`acceptSharingRequestSaga. Ready to make a request to process the Accept Sharing Request.`);
      yield acceptSharingRequest(objectSharingRequestTracker);

      // dispatch an action to indicate that the save operation was successful
      yield put(sharingRequestAcceptanceStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the save operation failed
      yield put(sharingRequestAcceptanceStatusChange(enumWorkflowState.Failure));
      yield put(sharingRequestAcceptanceFailureChange(error));
    }
  }
}
