import { typeUniqueId } from "../../../../dataObjects/types"
import { IObjectSharingRequestTracker } from "../../../../dataObjects/models/collaboration/ObjectSharingTracker";
import { enumSharableObjectType, enumSharingPermission, enumSharingRequestStatus } from '../../../../dataObjects/enums';
import { IFirestoreChannelRepository } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreChannelRepository';
import { updateSharingRequestStatus } from '.';
import { IChannel, IChannelAsJson } from '../../../../dataObjects/models/channels/Channel';
import { FirestoreDataRepositoryFactory } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory';
import { enumFirestoreDataRepositoryDataType } from '../../../cloudServices/googleFirebaseServices/database/firestore/enums';
import { IFirestoreBaseRepository } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository';
import { IUserSharingPermissions, UserSharingPermissions } from '../../../../dataObjects/models/collaboration/UserSharingPermissions';
import { getUserIdFromEmailAddress } from "../../../securityServices/userAccount/userAccountActions";
import { enumMdbErrorType } from "../../../../errorObjects/enums";
import { MdbError } from "../../../../errorObjects/MdbError";


/*-----------------------------------------------*/
/**
* @function acceptSharingRequest Process a request for a recipient user to Accept a Sharing Request.
* @param {IObjectSharingRequestTracker} sharingRequest The sharing request to be accepted.
* @returns {Promise<void>} A Promise (to provide asynchrounous capability) with no value.
*/
export function acceptSharingRequest(sharingRequest: IObjectSharingRequestTracker): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {

      // based on the type of Sharable Object, take some action
      switch (sharingRequest.sharingObjectType) {
        case enumSharableObjectType.Channel:
          // create local variable for the recipientUser's Id
          let recipientUserId: typeUniqueId | undefined = sharingRequest.recipientUserId;

          // If the recipientUserId of the Sharing Request is undefined, it means that the recipient was not a 
          // registered User at the time that the Sharing Request was issued. Since a request can only be accepted
          // by an existing User, it means that the user must have created a user account after the Sharing Request
          // was created. In that case, we need to perform a lookup of the user by email address to get the user's UserId
          if (recipientUserId === undefined) {
            recipientUserId = await getUserIdFromEmailAddress(sharingRequest.recipientEmail);

            if (recipientUserId === undefined) {
              throw new MdbError(`Unable to process acceptance of Sharing Request. Cannot determine the recipientUserId`, enumMdbErrorType.DataNotFound);
            }
          }

          // call local function to add the recipient user as a sharing member of the channel
          await addUserAsSharingMemberForChannel(sharingRequest.sharingObjectId, recipientUserId, sharingRequest.sharingPermission);

          // call function to update the status the sharingRequest to "Accepted"
          await updateSharingRequestStatus(sharingRequest, enumSharingRequestStatus.Accepted);
          break;

        default:
          throw new MdbError(`Unknown SharableObjectType in acceptSharingRequest: ${sharingRequest.sharingObjectType}`, enumMdbErrorType.InvalidOperation);
      }

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}


/*-----------------------------------------------*/
/**
* @function addUserAsSharedMemberForChannel Adds a User as a Sharing User to the specified Channel.
* @param {typeUniqueId} channelId Id of the Channel to be updated.
* @param {typeUniqueId} recipientUserId Id of the User to be added as a Sharing User.
* @param {enumSharingPermission} sharingPermission The Sharing Permission to specify for the Sharing User.
* @returns {Promise<void>} A Promise (to provide asynchrounous capability) with no value.
*/
function addUserAsSharingMemberForChannel(channelId: typeUniqueId, recipientUserId: typeUniqueId, sharingPermission: enumSharingPermission): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a Channel repository from the repository factory
      const firestoreChannelRepository: IFirestoreChannelRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Channel) as
        IFirestoreBaseRepository<IChannel, IChannelAsJson>;

      // get the latest version of the Channel, per the SharingObjectId
      const channel: IChannel | undefined = await firestoreChannelRepository.get(channelId);

      if (channel === undefined) {
        throw new MdbError(`Unable to process acceptance of Sharing Request. Cannot locate Channel with specified Id: ${channelId}`, enumMdbErrorType.InconsistentData);
      }

      // create a UserSharingPermissions object (combines userId and sharingPermission)
      const userSharingPermissions: IUserSharingPermissions = new UserSharingPermissions(recipientUserId, sharingPermission);

      // update the Channel, adding a new sharing member using the userSharingPermissions object
      channel.addUserAsChannelMember(userSharingPermissions);

      // save the updated Channel
      await firestoreChannelRepository.update(channel);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });

}
