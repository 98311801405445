import { Firestore, connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { Auth, connectAuthEmulator, getAuth, User } from 'firebase/auth';
import { FirebaseAppSingleton } from '../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton';
import { IUser, IUserAsJson } from '../../../../dataObjects/models/users/User';
import { IFirestoreUserRepository } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreUserRepository';
import { IChannel, IChannelAsJson } from '../../../../dataObjects/models/channels/Channel';
import { typeUniqueId } from '../../../../dataObjects/types';
import { IFirestoreChannelRepository } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreChannelRepository';
import { FirestoreDataRepositoryFactory } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory';
import { enumFirestoreDataRepositoryDataType } from '../../../cloudServices/googleFirebaseServices/database/firestore/enums';
import { IFirestoreBaseRepository } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository';
import { ICategory, ICategoryAsJson } from '../../../../dataObjects/models/categories/Category';
import { IFirestoreCategoryRepository } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreCategoryRepository';
import { FirebaseConfiguration, IFirebaseConfiguration } from '../../../cloudServices/googleFirebaseServices/FirebaseConfiguration';
import { FirestoreDbContext, IFirestoreDbContext } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDbContext';
import { FirestoreDigitalMediaRepository, IFirestoreDigitalMediaRepository } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDigitalMediaRepository';
import { IFirestoreAudioLinkRepository } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreAudioLinkRepository';
import { IAudioLink, IAudioLinkAsJson } from '../../../../dataObjects/models/digitalMedia/AudioLink';
import { IFirestoreHyperLinkRepository } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreHyperLinkRepository';
import { IHyperLink, IHyperLinkAsJson } from '../../../../dataObjects/models/digitalMedia/HyperLink';
import { IFirestoreImageLinkRepository } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreImageLinkRepository';
import { IImageLink, IImageLinkAsJson } from '../../../../dataObjects/models/digitalMedia/ImageLink';
import { IFirestoreNoteRepository } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreNoteRepository';
import { INote, INoteAsJson } from '../../../../dataObjects/models/digitalMedia/Note';
import { IFirestoreVideoLinkRepository } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreVideoLinkRepository';
import { IVideoLink, IVideoLinkAsJson } from '../../../../dataObjects/models/digitalMedia/VideoLink';
import { IFirestoreTopicItemRepository } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreTopicItemRepository';
import { ITopicItem, ITopicItemAsJson } from '../../../../dataObjects/models/topics/TopicItem';
import { IFirestoreTopicRepository } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreTopicRepository';
import { ITopic, ITopicAsJson } from '../../../../dataObjects/models/topics/Topic';
import { deleteAllCloudStorageFilesForUserId } from '../../../cloudStorageServices/cloudStorageFileDeleteActions';
import { FirebaseEmulatorSettings } from '../../../cloudServices/googleFirebaseServices/FirebaseConfigurationVariables';
import { FirestoreEmailRequestRepository_Ext, IFirestoreEmailRequestRepository, IFirestoreEmailRequestRepository_Ext } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreEmailRequestRepository';
import { IEmailRequest, IEmailRequestAsJson } from '../../../../dataObjects/models/emailRequests';
import { enumMdbErrorType } from '../../../../errorObjects/enums';
import { MdbError } from '../../../../errorObjects/MdbError';
import { FirestorePersistenceMetadataRepository, IFirestorePersistenceMetadataRepository } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestorePersistenceMetadataRepository';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { IFirestoreMembershipRepository } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreMembershipRepository';
import { IMembership, IMembershipAsJson } from '../../../../dataObjects/models/memberships/Membership';
import { IFirestoreSocialMediaPostRepository } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreSocialMediaPostRepository';
import { ISocialMediaPost, ISocialMediaPostAsJson } from '../../../../dataObjects/models/digitalMedia/SocialMediaPost';
import { IFirestoreThemeSpecsRepository } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreThemeSpecsRepository';
import { IThemeSpecs, IThemeSpecsAsJson } from '../../../../dataObjects/models/themes/ThemeSpecs';

export function expungeUserAccountAndAllResources(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // first, ensure that the user account indicated by the userId is not the same account as the currently logged in user
      await ensureUserAccountNotCurrentLoggedInUser(userId);

      // next, mark all records associated with the user account as 'Deleted'. We do this in case we experience errors along the way, 
      // so that records can be recovered if necessary
      await deleteAllRecordsForUserAccount(userId);

      // now, given that we succeeded in marking all records associated with the user as 'Deleted', expunge all records associated with user account
      await expungeAllRecordsForUserAccount(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function ensureUserAccountNotCurrentLoggedInUser(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get the current user's info from firebase authentication service
      const auth: Auth = getAuth(FirebaseAppSingleton.getInstance().firebaseApp);
      // if the environment is configured to use emulators and the auth emulator port is configured, connect to the emulator
      if (FirebaseEmulatorSettings.useEmulators) {
        if (FirebaseEmulatorSettings.authEmulatorPort) {
          connectAuthEmulator(auth, `http://localhost:${FirebaseEmulatorSettings.authEmulatorPort}`);
        }
      }
      const currentUser: User | null = auth.currentUser;

      // if the userId matches the current user's uid, throw an error (exception) to indicate a problem
      if (userId === currentUser?.uid) {
        throw new MdbError(`The user account for the current user cannot be expunged. Only a different user account can be expunged.`, enumMdbErrorType.InvalidOperation)
      }

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}


function deleteAllRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // mark all ThemeSpecs records associated with the user account as 'Deleted'
      await deleteThemeSpecsRecordsForUserAccount(userId);

      // mark all Email Request records associated with the user account as 'Deleted'
      await deleteEmailRequestRecordsForUserAccount(userId);

      // mark all Digital Media records associated with the user account as 'Deleted'
      await deleteDigitalMediaRecordsForUserAccount(userId);

      // mark all Topic Item records associated with the user account as 'Deleted'
      await deleteTopicItemRecordsForUserAccount(userId);

      // mark all Topic records associated with the user account as 'Deleted'
      await deleteTopicRecordsForUserAccount(userId);

      // mark all Channel records associated with the user account as 'Deleted'
      await deleteChannelRecordsForUserAccount(userId);

      // mark all Category records associated with the user account as 'Deleted'
      await deleteCategoryRecordsForUserAccount(userId);

      // mark the lone Membership record associated with the user account as 'Deleted'
      await deleteMembershipRecordForUserAccount(userId);

      // mark the lone User record associated with the user account as 'Deleted'
      await deleteUserRecordForUserAccount(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function deleteThemeSpecsRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get an ThemeSpecs repository from the repository factory
      const firestoreThemeSpecsRequestRepository: IFirestoreThemeSpecsRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.ThemeSpecs) as
        IFirestoreBaseRepository<IThemeSpecs, IThemeSpecsAsJson>;

      // call method to delete all records owned by the user account
      await firestoreThemeSpecsRequestRepository.deleteAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function deleteEmailRequestRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get an EmailRequest repository from the repository factory
      const firestoreEmailRequestRepository: IFirestoreEmailRequestRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.EmailRequest) as
        IFirestoreBaseRepository<IEmailRequest, IEmailRequestAsJson>;

      // call method to delete all records owned by the user account
      await firestoreEmailRequestRepository.deleteAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function deleteDigitalMediaRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {

      // mark all AudioLink records associated with the user account as 'Deleted'
      await deleteAudioLinkRecordsForUserAccount(userId);

      // mark all HyperLink records associated with the user account as 'Deleted'
      await deleteHyperLinkRecordsForUserAccount(userId);

      // mark all ImageLink records associated with the user account as 'Deleted'
      await deleteImageLinkRecordsForUserAccount(userId);

      // mark all Note records associated with the user account as 'Deleted'
      await deleteNoteRecordsForUserAccount(userId);

      // mark all SocialMediaPost records associated with the user account as 'Deleted'
      await deleteSocialMediaPostRecordsForUserAccount(userId);

      // mark all VideoLink records associated with the user account as 'Deleted'
      await deleteVideoLinkRecordsForUserAccount(userId);

      // mark all DigitalMedia records associated with the user account as 'Deleted'
      // initialize a Firebase App instance
      const firebaseConfig: IFirebaseConfiguration = new FirebaseConfiguration();
      firebaseConfig.enableOfflineSupport = false; // firestore offline persistence only works when running in a browser context
      const firebaseApp = FirebaseAppSingleton.getInstance(firebaseConfig).firebaseApp;

      // instantiate a FirestoreDbContext
      // const dbContext: IFirestoreDbContext = new FirestoreDbContext('firestoreDb', getFirestore(firebaseApp));
      const db: Firestore = getFirestore(firebaseApp);
      // if the environment is configured to use emulators and the firestore emulator port is configured, connect to the emulator
      if (FirebaseEmulatorSettings.useEmulators) {
        if (FirebaseEmulatorSettings.firestoreEmulatorPort) {
          connectFirestoreEmulator(db, `localhost`, parseInt(FirebaseEmulatorSettings.firestoreEmulatorPort));
        }
      }
      const dbContext: IFirestoreDbContext = new FirestoreDbContext('firestoreDb', db);

      // create a FirestoreDigitalMediaRepository
      const firestoreDigitalMediaRepository: IFirestoreDigitalMediaRepository = new FirestoreDigitalMediaRepository(dbContext);

      // call method to delete all records owned by the user account
      await firestoreDigitalMediaRepository.deleteAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function deleteAudioLinkRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get an AudioLink repository from the repository factory
      const firestoreAudioLinkRepository: IFirestoreAudioLinkRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.AudioLink) as
        IFirestoreBaseRepository<IAudioLink, IAudioLinkAsJson>;

      // call method to delete all records owned by the user account
      await firestoreAudioLinkRepository.deleteAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function deleteHyperLinkRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a HyperLink repository from the repository factory
      const firestoreHyperLinkRepository: IFirestoreHyperLinkRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.HyperLink) as
        IFirestoreBaseRepository<IHyperLink, IHyperLinkAsJson>;

      // call method to delete all records owned by the user account
      await firestoreHyperLinkRepository.deleteAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function deleteImageLinkRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get an ImageLink repository from the repository factory
      const firestoreImageLinkRepository: IFirestoreImageLinkRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.ImageLink) as
        IFirestoreBaseRepository<IImageLink, IImageLinkAsJson>;

      // call method to delete all records owned by the user account
      await firestoreImageLinkRepository.deleteAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function deleteNoteRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a Note repository from the repository factory
      const firestoreNoteRepository: IFirestoreNoteRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Note) as
        IFirestoreBaseRepository<INote, INoteAsJson>;

      // call method to delete all records owned by the user account
      await firestoreNoteRepository.deleteAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function deleteSocialMediaPostRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a SocialMediaPost repository from the repository factory
      const firestoreSocialMediaPostRepository: IFirestoreSocialMediaPostRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.SocialMediaPost) as
        IFirestoreBaseRepository<ISocialMediaPost, ISocialMediaPostAsJson>;

      // call method to delete all records owned by the user account
      await firestoreSocialMediaPostRepository.deleteAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function deleteVideoLinkRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a VideoLink repository from the repository factory
      const firestoreVideoLinkRepository: IFirestoreVideoLinkRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.VideoLink) as
        IFirestoreBaseRepository<IVideoLink, IVideoLinkAsJson>;

      // call method to delete all records owned by the user account
      await firestoreVideoLinkRepository.deleteAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function deleteTopicItemRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a TopicItem repository from the repository factory
      const firestoreTopicItemRepository: IFirestoreTopicItemRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.TopicItem) as
        IFirestoreBaseRepository<ITopicItem, ITopicItemAsJson>;

      // call method to delete all records owned by the user account
      await firestoreTopicItemRepository.deleteAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function deleteTopicRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a Topic repository from the repository factory
      const firestoreTopicRepository: IFirestoreTopicRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Topic) as
        IFirestoreBaseRepository<ITopic, ITopicAsJson>;

      // call method to delete all records owned by the user account
      await firestoreTopicRepository.deleteAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function deleteChannelRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a Channel repository from the repository factory
      const firestoreChannelRepository: IFirestoreChannelRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Channel) as
        IFirestoreBaseRepository<IChannel, IChannelAsJson>;

      // call method to delete all records owned by the user account
      await firestoreChannelRepository.deleteAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function deleteCategoryRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a Category repository from the repository factory
      const firestoreCategoryRepository: IFirestoreCategoryRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Category) as
        IFirestoreBaseRepository<ICategory, ICategoryAsJson>;

      // call method to delete all records owned by the user account
      await firestoreCategoryRepository.deleteAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function deleteMembershipRecordForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a Membership repository from the repository factory
      const firestoreMembershipRepository: IFirestoreMembershipRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Membership) as
        IFirestoreBaseRepository<IMembership, IMembershipAsJson>;

      // call method to delete all records owned by the user account
      await firestoreMembershipRepository.deleteAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function deleteUserRecordForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a User repository from the repository factory
      const firestoreUserRepository: IFirestoreUserRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.User) as
        IFirestoreBaseRepository<IUser, IUserAsJson>;

      // call method to delete all records owned by the user account
      await firestoreUserRepository.delete(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function expungeAllRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // expunge all ThemeSpecs records associated with the user account 
      await expungeThemeSpecsRecordsForUserAccount(userId);

      // expunge all Email Request records associated with the user account 
      await expungeEmailRequestRecordsForUserAccount(userId);

      // expunge Digital Media files associated with the user account
      await expungeDigitalMediaFilesForUserAccount(userId);

      // expunge all Digital Media records associated with the user account 
      await expungeDigitalMediaRecordsForUserAccount(userId);

      // expunge all Topic Item records associated with the user account 
      await expungeTopicItemRecordsForUserAccount(userId);

      // expunge all Topic records associated with the user account 
      await expungeTopicRecordsForUserAccount(userId);

      // expunge all Channel records associated with the user account 
      await expungeChannelRecordsForUserAccount(userId);

      // expunge all Category records associated with the user account 
      await expungeCategoryRecordsForUserAccount(userId);

      // expunge the lone Membership record associated with the user account 
      await expungeMembershipRecordForUserAccount(userId);

      // expunge the lone User record associated with the user account 
      await expungeUserRecordForUserAccount(userId);

      // *** 2022-02-24: We will NOT expunge Persistence Metadata records as they will be retained to re-create account data, if necessary
      // *** 2023-07-07: Change of direction. We WILL expunge Persistence Metadata records.
      // expunge all Persistence Metadata records associated with the user account 
      await expungePersistenceMetadataRecordsForUserAccount(userId);

      // lastly, delete the user account from Firebase Authentication
      await expungeUserAccountFromFirebaseAuth(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function expungeThemeSpecsRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get an ThemeSpecs repository from the repository factory
      const firestoreThemeSpecsRepository: IFirestoreThemeSpecsRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.ThemeSpecs) as
        IFirestoreBaseRepository<IThemeSpecs, IThemeSpecsAsJson>;

      // call method to expunge all records owned by the user account
      await firestoreThemeSpecsRepository.expungeAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function expungeEmailRequestRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get an EmailRequest repository from the repository factory
      const firestoreEmailRequestRepository: IFirestoreEmailRequestRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.EmailRequest) as
        IFirestoreBaseRepository<IEmailRequest, IEmailRequestAsJson>;

      // call method to expunge all records owned by the user account
      await firestoreEmailRequestRepository.expungeAllForOwner(userId);

      // initialize a Firestore object instance
      const firestoreObj: Firestore = FirebaseAppSingleton.firestore;

      // create an instance of the EmailRequest Extended repository
      const firestoreCategoryRepository_Ext: IFirestoreEmailRequestRepository_Ext = new FirestoreEmailRequestRepository_Ext(firestoreObj);

      // call the method to expunge all Email Request records based on the userId (where the id begins with the user's id)
      await firestoreCategoryRepository_Ext.expungeEmailRequestsForUserId(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function expungeDigitalMediaFilesForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {

      // expunge the logical folder, and all logical contents, in Firebase Storage Mgt associated with the user account
      deleteAllCloudStorageFilesForUserId(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function expungeDigitalMediaRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {

      // expunge all AudioLink records associated with the user account 
      await expungeAudioLinkRecordsForUserAccount(userId);

      // expunge all HyperLink records associated with the user account 
      await expungeHyperLinkRecordsForUserAccount(userId);

      // expunge all ImageLink records associated with the user account 
      await expungeImageLinkRecordsForUserAccount(userId);

      // expunge all Note records associated with the user account 
      await expungeNoteRecordsForUserAccount(userId);

      // expunge all SocialMediaPost records associated with the user account 
      await expungeSocialMediaPostRecordsForUserAccount(userId);

      // expunge all VideoLink records associated with the user account 
      await expungeVideoLinkRecordsForUserAccount(userId);

      // expunge all DigitalMedia records associated with the user account 
      // initialize a Firebase App instance
      const firebaseConfig: IFirebaseConfiguration = new FirebaseConfiguration();
      firebaseConfig.enableOfflineSupport = false; // firestore offline persistence only works when running in a browser context
      const firebaseApp = FirebaseAppSingleton.getInstance(firebaseConfig).firebaseApp;

      // instantiate a FirestoreDbContext
      // const dbContext: IFirestoreDbContext = new FirestoreDbContext('firestoreDb', getFirestore(firebaseApp));
      const db: Firestore = getFirestore(firebaseApp);
      // if the environment is configured to use emulators and the firestore emulator port is configured, connect to the emulator
      if (FirebaseEmulatorSettings.useEmulators) {
        if (FirebaseEmulatorSettings.firestoreEmulatorPort) {
          connectFirestoreEmulator(db, `localhost`, parseInt(FirebaseEmulatorSettings.firestoreEmulatorPort));
        }
      }
      const dbContext: IFirestoreDbContext = new FirestoreDbContext('firestoreDb', db);

      // create a FirestoreDigitalMediaRepository
      const firestoreDigitalMediaRepository: IFirestoreDigitalMediaRepository = new FirestoreDigitalMediaRepository(dbContext);

      // call method to expunge all records owned by the user account
      await firestoreDigitalMediaRepository.expungeAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function expungeAudioLinkRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get an AudioLink repository from the repository factory
      const firestoreAudioLinkRepository: IFirestoreAudioLinkRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.AudioLink) as
        IFirestoreBaseRepository<IAudioLink, IAudioLinkAsJson>;

      // call method to expunge all records owned by the user account
      await firestoreAudioLinkRepository.expungeAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function expungeHyperLinkRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a HyperLink repository from the repository factory
      const firestoreHyperLinkRepository: IFirestoreHyperLinkRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.HyperLink) as
        IFirestoreBaseRepository<IHyperLink, IHyperLinkAsJson>;

      // call method to expunge all records owned by the user account
      await firestoreHyperLinkRepository.expungeAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function expungeImageLinkRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get an ImageLink repository from the repository factory
      const firestoreImageLinkRepository: IFirestoreImageLinkRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.ImageLink) as
        IFirestoreBaseRepository<IImageLink, IImageLinkAsJson>;

      // call method to expunge all records owned by the user account
      await firestoreImageLinkRepository.expungeAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function expungeNoteRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a Note repository from the repository factory
      const firestoreNoteRepository: IFirestoreNoteRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Note) as
        IFirestoreBaseRepository<INote, INoteAsJson>;

      // call method to expunge all records owned by the user account
      await firestoreNoteRepository.expungeAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function expungeSocialMediaPostRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a SocialMediaPost repository from the repository factory
      const firestoreSocialMediaPostRepository: IFirestoreSocialMediaPostRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.SocialMediaPost) as
        IFirestoreBaseRepository<ISocialMediaPost, ISocialMediaPostAsJson>;

      // call method to expunge all records owned by the user account
      await firestoreSocialMediaPostRepository.expungeAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function expungeVideoLinkRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a VideoLink repository from the repository factory
      const firestoreVideoLinkRepository: IFirestoreVideoLinkRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.VideoLink) as
        IFirestoreBaseRepository<IVideoLink, IVideoLinkAsJson>;

      // call method to expunge all records owned by the user account
      await firestoreVideoLinkRepository.expungeAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function expungeTopicItemRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a TopicItem repository from the repository factory
      const firestoreTopicItemRepository: IFirestoreTopicItemRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.TopicItem) as
        IFirestoreBaseRepository<ITopicItem, ITopicItemAsJson>;

      // call method to expunge all records owned by the user account
      await firestoreTopicItemRepository.expungeAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function expungeTopicRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a Topic repository from the repository factory
      const firestoreTopicRepository: IFirestoreTopicRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Topic) as
        IFirestoreBaseRepository<ITopic, ITopicAsJson>;

      // call method to expunge all records owned by the user account
      await firestoreTopicRepository.expungeAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function expungeChannelRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a Channel repository from the repository factory
      const firestoreChannelRepository: IFirestoreChannelRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Channel) as
        IFirestoreBaseRepository<IChannel, IChannelAsJson>;

      // call method to expunge all records owned by the user account
      await firestoreChannelRepository.expungeAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function expungeCategoryRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a Category repository from the repository factory
      const firestoreCategoryRepository: IFirestoreCategoryRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Category) as
        IFirestoreBaseRepository<ICategory, ICategoryAsJson>;

      // call method to expunge all records owned by the user account
      await firestoreCategoryRepository.expungeAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function expungeMembershipRecordForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a Membership repository from the repository factory
      const firestoreMembershipRepository: IFirestoreMembershipRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Membership) as
        IFirestoreBaseRepository<IMembership, IMembershipAsJson>;

      // call method to expunge all records owned by the user account
      await firestoreMembershipRepository.expungeAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function expungeUserRecordForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a User repository from the repository factory
      const firestoreUserRepository: IFirestoreUserRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.User) as
        IFirestoreBaseRepository<IUser, IUserAsJson>;

      // call method to expunge the record for the user account
      await firestoreUserRepository.expunge(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function expungePersistenceMetadataRecordsForUserAccount(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // instantiate a FirestorePersistenceMetadataRepository, injecting the Firestore object instance
      const firestorePersistenceMetadataRepository: IFirestorePersistenceMetadataRepository = new FirestorePersistenceMetadataRepository(FirebaseAppSingleton.firestore);

      // call method to expunge all records owned by the user account
      await firestorePersistenceMetadataRepository.expungeAllForOwner(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

// function haveAllNonPersistenceMetadataRecordsBeenExpunged(): boolean {
//   let retVal: boolean = false;

//   return retVal;
// }

function expungeUserAccountFromFirebaseAuth(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      const functions = getFunctions(FirebaseAppSingleton.getInstance().firebaseApp);
      const deleteUserAccountFromAuth =
        httpsCallable<string, void>(functions, 'deleteUserAccountFromAuth');
      deleteUserAccountFromAuth(userId)
        .then(() => {
          resolve();
        });
    } catch (error: any) {
      reject(error);
    }
  });
}
