import { typeUniqueId } from "../../../../dataObjects/types"
import { generateEmailRequestForPasswordReset, submitEmailRequestToServer } from ".";
import { IEmailRequest } from "../../../../dataObjects/models/emailRequests";

/**
 * @function submitEmailRequestForPasswordReset Generates an email request to allow resetting the user's password.
 * @param {typeUniqueId} userId The Id of the user who is the target of the email.
 * @param {string} emailAddress The email address of the target of the email.
 * @param {string} firstName The first name of the target of the email.
 * @param {string} firstName The last name of the target of the email.
 * @returns {void} No return value
 */
export function submitEmailRequestForPasswordReset(userId: typeUniqueId, emailAddress: string, firstName: string, lastName: string): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // whether to display console logs (displayConsoleLogs && console.log statements)
      const displayConsoleLogs: boolean = false;

      displayConsoleLogs && console.log(`%c Entered submitEmailRequestForPasswordReset()`, 'background: #055; color: #fff');

      // call method to generate the Password Reset email request, which will return the Email Request
      const emailRequest: IEmailRequest = await generateEmailRequestForPasswordReset(userId, emailAddress.toLowerCase(), firstName, lastName);

      displayConsoleLogs && console.log(`%c In submitEmailRequestForPasswordReset. Returned from call to generateEmailRequestForPasswordReset. emailRequest: ${JSON.stringify(emailRequest)}`, 'background: #00B; color: #fff');

      // submit email request to the server
      await submitEmailRequestToServer(emailRequest);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}
