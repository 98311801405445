import { take, put } from 'redux-saga/effects';
import { updateUserEmailAddress } from '../../../firebaseServices/dataServices/dataServiceActions/userActions';
import { userUpdateEmailAddressRequest } from '../../slices/user/userUpdateEmailAddressDataSlice';
import { userUpdateEmailAddressStatusChange } from '../../slices/user/userUpdateEmailAddressStatusSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { userUpdateEmailAddressFailure } from '../../slices/user/userUpdateEmailAddressFailureSlice';

/**
 * @function updateUserEmailAddressSaga A saga workflow pertaining to updating the current user's email address.
 */
export function* updateUserEmailAddressSaga() {
  // console.info('(updateUserEmailAddressSaga) entered updateUserEmailAddressSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the userUpdateEmailAddressRequest action to be dispatched
      const userAccountExpungeRequestData = yield take(userUpdateEmailAddressRequest);
      const { payload: userAndEmailAddress } = userAccountExpungeRequestData;

      const newEmailAddress: string = userAndEmailAddress.emailAddress.toLowerCase();

      // make a request to update the email address for the user account, per the email address
      yield updateUserEmailAddress(newEmailAddress);

      // dispatch an action to indicate that the email address update operation was successful
      yield put(userUpdateEmailAddressStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the email address update failed
      yield put(userUpdateEmailAddressStatusChange(enumWorkflowState.Failure));
      yield put(userUpdateEmailAddressFailure(error));
    }
  }
}
