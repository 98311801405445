import { take, put } from 'redux-saga/effects';
// import { userRegistrationSuccess, userRegistrationFailure } from '../../actions';
import { registerUser } from '../../../firebaseServices/securityServices/userAccount/userAccountActions';
import { logout } from '../../../firebaseServices/securityServices/userAccount/userAccountActions';
import { userRegistrationRequest, userRegistrationSuccess } from '../../slices/registration/userRegistrationDataSlice';
import { IRegistrationData } from '../../../dataObjects/models/registerAndLogin/RegistrationData';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { userRegistrationFailure } from '../../slices/registration/userRegistrationFailureSlice';
import { userRegistrationStatusChange } from '../../slices/registration/userRegistrationStatusSlice';

/**
 * @function userRegistrationRequestedSaga A saga workflow pertaining to a user having requested to register for a user account.
 */
export function* userRegistrationRequestedSaga() {
  // console.info('(userRegistrationRequestedSaga) entered userRegistrationRequestedSaga');

  const displayConsoleLogs: boolean = false;

  displayConsoleLogs && console.log('%c (userRegistrationRequestedSaga) Entered userRegistrationRequestedSaga', 'background: #B0B; color: #fff');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the registrationRequest action to be dispatched
      const userRegistrationRequestData = yield take(userRegistrationRequest);
      const { payload: userRegistrationData } = userRegistrationRequestData;

      // make a request to register the user
      yield registerUser(userRegistrationData);

      // if the user registration was successful, we will proceed to the next statement (if registration failed, 
      // control will jump to 'catch' block). Mask out the password of the user registration data, and then dispatch 
      // that the registration was successful, passing the registration data with the masked out password.
      const registrationDataWithMaskedPassword: IRegistrationData = { ...userRegistrationData, _password: '********' };
      yield put(userRegistrationSuccess(registrationDataWithMaskedPassword));

      displayConsoleLogs && console.log(`%c (userRegistrationRequestedSaga) Registration succeeded. registrationDataWithMaskedPassword: ${JSON.stringify(registrationDataWithMaskedPassword)}`, 'background: #B0B; color: #fff');

      // after registering the user, we need to logout the user. After verifying their account, the user can complete the registration process
      yield logout();

      // dispatch an action to indicate that the userRegistration was successful
      yield put(userRegistrationSuccess(userRegistrationData));
    } catch (error: any) {
      displayConsoleLogs && console.log(`%c (userRegistrationRequestedSaga) Caught an exception. error: ${JSON.stringify(error)}`, 'background: #B0B; color: #fff');
      yield put(userRegistrationStatusChange(enumWorkflowState.Failure));
      // dispatch an action to indicate that the userRegistration failed
      yield put(userRegistrationFailure(error));
    }
  }
}
