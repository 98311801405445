import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, MenuItem, TextField, TextFieldProps } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormWithActionBar from '../FormWithActionBar/FormWithActionBar';
import { ControlsStringAssets, KeyValuePairsStringAssets, MessagesStringAssets } from '../../../assets/stringAssets';
import { IUserRedemptionCodeRequest } from '../../../dataObjects/models/userRedemptionCodeRequest';
import dayjs, { Dayjs } from 'dayjs';
import { DateTimeValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers/models';
import { enumSubscriptionTypeConvert } from '../../../dataObjects/enums';
import { styled } from '@mui/styles';

// a styled TextField that is serving as a Select control
const StyledTextFieldForSelectControl = styled((props: TextFieldProps) => (
  <TextField
    select
    fullWidth
    {...props}
  />
))(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));


interface IUserRedemptionCodeRequestFormValues {
  recipientName: string;
  recipientEmailAddress: string;
  subscriptionType: string;
  startDate: Date;
  endDate: Date;
}

// using 'yup', set up a schema for the form field values
const schema = yup.object().shape({
  recipientName: yup
    .string()
    .required(ControlsStringAssets.userRedemptionCodeRequestRecipientNameRequired),
  recipientEmailAddress: yup
    .string()
    .email(ControlsStringAssets.emailInvalid)
    .required(ControlsStringAssets.userRedemptionCodeRequestRecipientEmailAddressRequired),
  subscriptionType: yup
    .string()
    .required(ControlsStringAssets.userRedemptionCodeRequestSubscriptionTypeRequired),
  startDate: yup
    .date()
    .required(ControlsStringAssets.userRedemptionCodeRequestSubscriptionStartDateRequired)
    .default(() => (new Date())),
  endDate: yup
    .date()
    .required(ControlsStringAssets.userRedemptionCodeRequestSubscriptionEndDateRequired)
    .default(() => (new Date()))
});

export interface IUserRedemptionCodeRequestFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {IUserRedemptionCodeRequest} userRedemptionCodeRequest The UserRedemptionCodeRequest details for the form (will have blank properties values if we're creating a new record)
   */
  userRedemptionCodeRequest: IUserRedemptionCodeRequest,
  /**
   * @property {boolean} saveRequestInProgress Whether a save request is in progress
   */
  saveRequestInProgress?: boolean,
  /**
   * @property {(topic: IUserRedemptionCodeRequest) => Promise<void>} onSubmit Method to call for submitting the form for a save operation
   */
  onSubmit: (userRedemptionCodeRequest: IUserRedemptionCodeRequest) => Promise<void>,
}

const UserRedemptionCodeRequestForm: React.FC<IUserRedemptionCodeRequestFormProps> = (props: IUserRedemptionCodeRequestFormProps) => {
  UserRedemptionCodeRequestForm.displayName = 'UserRedemptionCodeRequest Form';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  // get required arguments from props
  const { userRedemptionCodeRequest, onSubmit } = props;

  displayConsoleLogs && console.log(`UserRedemptionCodeRequestForm. Entered UserRedemptionCodeRequestForm. userRedemptionCodeRequest (stringified): ${JSON.stringify(userRedemptionCodeRequest)}`);

  // const [someDate, setSomeDate] = useState<string>("2020-02-04 8:45");

  // const dateTimePickerFormat = "YYYY-MM-DD hh:mm A";

  // set up details for ReactHookForm
  const { register, formState, formState: { errors }, handleSubmit } = useForm<IUserRedemptionCodeRequestFormValues>({
    defaultValues: {
      recipientName: userRedemptionCodeRequest.recipientName,
      recipientEmailAddress: userRedemptionCodeRequest.recipientEmailAddress,
      subscriptionType: userRedemptionCodeRequest.subscriptionType,
      startDate: userRedemptionCodeRequest.startDate,
      endDate: userRedemptionCodeRequest.endDate
    },
    // mode: "onBlur",
    mode: "all",
    resolver: yupResolver(schema)
  });

  const { ref: recipientNameReg, ...recipientNameProps } = register("recipientName", { required: true });
  const { ref: recipientEmailAddressReg, ...recipientEmailAddressProps } = register("recipientEmailAddress", { required: true });
  const { ref: subscriptionTypeReg, ...subscriptionTypeProps } = register("subscriptionType", { required: true });
  const { ref: startDateReg, ...startDateProps } = register("startDate", { required: true });
  const { ref: endDateReg, ...endDateProps } = register("endDate", { required: true });

  // for testing whether the form is in a valid state (cast 'isValid' to 'formIsValid')
  const { isValid: formIsValid } = formState;

  // capture whether a save is currently being submitted
  const saveRequestInProgress: boolean = props.saveRequestInProgress ?? false;

  // state value indicating whether a save is in progress
  const [saveInProgress, setSaveInProgress] = useState<boolean>(saveRequestInProgress);

  // currently selected subscriptionType (subscriptionTypeKey is the selected item's value)
  const [subscriptionTypeKey, setSubscriptionTypeKey] = useState<string>("");

  const [subscriptionTypesForDropdown, setSubscriptionTypesForDropdown] = useState<Array<JSX.Element>>([]);

  const [startDateValue, setStartDateValue] = useState<Dayjs | null | undefined>(dayjs(userRedemptionCodeRequest.startDate));
  const [endDateValue, setEndDateValue] = useState<Dayjs | null | undefined>(dayjs(userRedemptionCodeRequest.endDate));

  // useEffect to be executed upon mounting of this component
  useEffect(() => {
    // prepare an array of SubscriptionType values from the enumSubscriptionType enumerator that can be used to populate MenuItem components for the SubscriptionType <Select> component
    let subscriptionTypeMenuItems: Array<JSX.Element> = [];
    KeyValuePairsStringAssets.subscriptionTypeValuePairs.forEach((keyValuePair: { key: string, value: string }) => {
      subscriptionTypeMenuItems.push(<MenuItem key={keyValuePair.key} value={keyValuePair.key}>{keyValuePair.value}</MenuItem>);
    });

    setSubscriptionTypesForDropdown(subscriptionTypeMenuItems);
  }, []);

  // useEffect hook for setting the 'startDateValue' & 'endDateValue' local state based on the value of userRedemptionCodeRequest.startDate & userRedemptionCodeRequest.endDate
  useEffect(() => {
    setStartDateValue(dayjs(userRedemptionCodeRequest.startDate));
  }, [userRedemptionCodeRequest.startDate]);

  // useEffect hook for setting the 'saveInProgress' local state based on whether a save is currently in progress
  useEffect(() => {
    setSaveInProgress(saveRequestInProgress);
  }, [saveRequestInProgress]);

  // handles a save/submit request from the form
  const handleSaveSubmit = async (data: IUserRedemptionCodeRequestFormValues) => {

    setSaveInProgress(true);

    // fill in name, description, whether to use timestamp, and timestamp of the UserRedemptionCodeRequest object passed in
    userRedemptionCodeRequest.recipientName = data.recipientName;
    userRedemptionCodeRequest.recipientEmailAddress = data.recipientEmailAddress.toLowerCase();
    userRedemptionCodeRequest.subscriptionType = enumSubscriptionTypeConvert.fromString(data.subscriptionType);
    userRedemptionCodeRequest.startDate = startDateValue ? startDateValue.toDate() : dayjs().toDate();
    userRedemptionCodeRequest.endDate = endDateValue ? endDateValue.toDate() : dayjs().toDate();

    // call the onSubmit handler passed in, supplying the Topic object
    await onSubmit(userRedemptionCodeRequest);
  }

  // present the form
  return (
    <>
      <FormWithActionBar
        onSubmit={handleSubmit(handleSaveSubmit)}
        actionInProgress={saveInProgress}
        actionInProgressLabel={MessagesStringAssets.userRedemptionCodeRequest_SaveRequested}
        formIsValid={formIsValid}
      >
        <TextField
          inputRef={recipientNameReg}
          {...recipientNameProps}
          autoFocus
          label={ControlsStringAssets.userRedemptionCodeRequestRecipientNameLabel}
          margin='normal'
          fullWidth
          error={!!errors.recipientName}
          helperText={errors?.recipientName?.message}
          InputLabelProps={{
            required: true  // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

        <TextField
          inputRef={recipientEmailAddressReg}
          {...recipientEmailAddressProps}
          autoFocus
          label={ControlsStringAssets.userRedemptionCodeRequestRecipientEmailAddressLabel}
          margin='normal'
          fullWidth
          error={!!errors.recipientEmailAddress}
          helperText={errors?.recipientEmailAddress?.message}
          InputLabelProps={{
            required: true  // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

        {/* We use a TextField with 'select' attribute as a pseudo <Select> (or dropdown) control */}
        <StyledTextFieldForSelectControl
          inputRef={subscriptionTypeReg}
          label="Membership Type *"
          margin='normal'
          value={subscriptionTypeKey}
          onChange={e => setSubscriptionTypeKey(e.target.value)}
        >
          {subscriptionTypesForDropdown}
        </StyledTextFieldForSelectControl>

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DateTimePicker
              label={ControlsStringAssets.userRedemptionCodeRequestSubscriptionStartDateLabel}
              inputRef={startDateReg}
              // inputFormat="YYYY/MM/DD hh:mm a"
              disablePast={false}
              value={startDateValue ? dayjs(startDateValue) : null}
              onChange={(value: Dayjs | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => { setStartDateValue(value) }}
              views={["year", "month", "day"]}
            >
            </DateTimePicker>
          </LocalizationProvider >

          <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DateTimePicker
              label={ControlsStringAssets.userRedemptionCodeRequestSubscriptionEndDateLabel}
              inputRef={endDateReg}
              // inputFormat="YYYY/MM/DD hh:mm a"
              disablePast={false}
              value={endDateValue ? dayjs(endDateValue) : null}
              onChange={(value: Dayjs | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => { setEndDateValue(value) }}
              views={["year", "month", "day"]}
            >
            </DateTimePicker>
          </LocalizationProvider >
        </Box>

      </FormWithActionBar>
    </>

  );
}

export default UserRedemptionCodeRequestForm;